@charset "UTF-8";

@font-face {
  font-family: "base";
  src:url("fonts/base.eot");
  src:url("fonts/base.eot?#iefix") format("embedded-opentype"),
    url("fonts/base.woff") format("woff"),
    url("fonts/base.ttf") format("truetype"),
    url("fonts/base.svg#base") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "base" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "base" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-slick-next:before {
  content: "\e000";
}
.icon-slick-previous:before {
  content: "\e001";
}
.icon-slick-dot:before {
  content: "\e002";
}
.icon-slick-refresh:before {
  content: "\e003";
}
.icon-direction:before {
  content: "\e004";
}
.icon-menu:before {
  content: "\e005";
}
.icon-chevron-down:before {
  content: "\e006";
}
.icon-chevron-left:before {
  content: "\e007";
}
.icon-chevron-right:before {
  content: "\e008";
}
.icon-chevron-small-down:before {
  content: "\e009";
}
.icon-chevron-small-right-1:before {
  content: "\e00a";
}
.icon-chevron-small-right:before {
  content: "\e00b";
}
.icon-chevron-small-up:before {
  content: "\e00c";
}
.icon-chevron-with-circle-down:before {
  content: "\e00d";
}
.icon-chevron-with-circle-left:before {
  content: "\e00e";
}
.icon-chevron-with-circle-right:before {
  content: "\e00f";
}
.icon-chevron-with-circle-up:before {
  content: "\e010";
}
.icon-compass:before {
  content: "\e011";
}
.icon-landline:before {
  content: "\e012";
}
.icon-phone-left:before {
  content: "\e013";
}
.icon-mail:before {
  content: "\e014";
}
.icon-facebook:before {
  content: "\e015";
}
.icon-youtube-with-circle:before {
  content: "\e017";
}
.icon-twitter:before {
  content: "\e018";
}
.icon-twitter-with-circle:before {
  content: "\e019";
}
.icon-pinterest:before {
  content: "\e01a";
}
.icon-pinterest-with-circle:before {
  content: "\e01b";
}
.icon-linkedin:before {
  content: "\e01c";
}
.icon-linkedin-with-circle:before {
  content: "\e01d";
}
.icon-google:before {
  content: "\e01e";
}
.icon-google-with-circle:before {
  content: "\e01f";
}
.icon-facebook-with-circle:before {
  content: "\e020";
}
.icon-search:before {
  content: "\e021";
}
.icon-cross:before {
  content: "\e022";
}
.icon-plus:before {
  content: "\e023";
}
.icon-check:before {
  content: "\e024";
}
.icon-share:before {
  content: "\e025";
}
.icon-minus:before {
  content: "\e026";
}
.icon-squared-cross:before {
  content: "\e027";
}
.icon-squared-minus:before {
  content: "\e028";
}
.icon-squared-plus:before {
  content: "\e029";
}
.icon-warning:before {
  content: "\e02a";
}
.icon-users:before {
  content: "\e02d";
}
.icon-circle-with-cross:before {
  content: "\e02e";
}
.icon-circle-with-minus:before {
  content: "\e02f";
}
.icon-user:before {
  content: "\e031";
}
.icon-shopping-cart:before {
  content: "\e032";
}
.icon-shopping-basket:before {
  content: "\e033";
}
.icon-shopping-bag:before {
  content: "\e034";
}
.icon-old-phone:before {
  content: "\e035";
}
.icon-location-pin:before {
  content: "\e036";
}
.icon-info-with-circle:before {
  content: "\e037";
}
.icon-circle-with-plus:before {
  content: "\e030";
}
.icon-vimeo:before {
  content: "\e038";
}
.icon-vimeo-with-circle:before {
  content: "\e039";
}
.icon-attachment:before {
  content: "\e03b";
}
.icon-text-document:before {
  content: "\e03a";
}
.icon-text-document-inverted:before {
  content: "\e03c";
}
.icon-foursquare:before {
  content: "\e03d";
}
.icon-instagram:before {
  content: "\e03f";
}
.icon-instagram-with-circle:before {
  content: "\e040";
}
.icon-tumblr-with-circle:before {
  content: "\e041";
}
.icon-tumblr:before {
  content: "\e042";
}
.icon-star:before {
  content: "\e043";
}
.icon-print:before {
  content: "\e046";
}
.icon-heart:before {
  content: "\e047";
}
.icon-bar-graph:before {
  content: "\e048";
}
.icon-calendar:before {
  content: "\e049";
}
.icon-pencil:before {
  content: "\e04a";
}
.icon-controller-play:before {
  content: "\e04b";
}
.icon-triangle-down:before {
  content: "\e04c";
}
.icon-triangle-left:before {
  content: "\e04d";
}
.icon-triangle-right:before {
  content: "\e04e";
}
.icon-triangle-up:before {
  content: "\e04f";
}
.icon-thumbs-down:before {
  content: "\e050";
}
.icon-thumbs-up:before {
  content: "\e051";
}
.icon-star-outlined:before {
  content: "\e052";
}
.icon-tag:before {
  content: "\e053";
}
.icon-select-arrows:before {
  content: "\e054";
}
.icon-resize-100:before {
  content: "\e055";
}
.icon-resize-full-screen:before {
  content: "\e056";
}
.icon-heart-outlined:before {
  content: "\e057";
}
.icon-help:before {
  content: "\e058";
}
.icon-help-with-circle:before {
  content: "\e059";
}
.icon-home:before {
  content: "\e05a";
}
.icon-image:before {
  content: "\e05b";
}
.icon-info:before {
  content: "\e05c";
}
.icon-edit:before {
  content: "\e05d";
}
.icon-flag:before {
  content: "\e05e";
}
.icon-eye:before {
  content: "\e05f";
}
.icon-eye-with-line:before {
  content: "\e060";
}
.icon-chevron-thin-down:before {
  content: "\e061";
}
.icon-chevron-thin-left:before {
  content: "\e062";
}
.icon-chevron-thin-right:before {
  content: "\e063";
}
.icon-chevron-thin-up:before {
  content: "\e064";
}
.icon-chevron-up:before {
  content: "\e065";
}
.icon-cw:before {
  content: "\e066";
}
.icon-cycle:before {
  content: "\e067";
}
.icon-controller-paus:before {
  content: "\e068";
}
.icon-clock:before {
  content: "\e069";
}
.icon-code:before {
  content: "\e06a";
}
.icon-chat:before {
  content: "\e06b";
}
.icon-beamed-note:before {
  content: "\e06c";
}
.icon-credit-card:before {
  content: "\e06d";
}
.icon-export:before {
  content: "\e06e";
}
.icon-flash:before {
  content: "\e06f";
}
.icon-globe:before {
  content: "\e070";
}
.icon-map:before {
  content: "\e071";
}
.icon-lock:before {
  content: "\e072";
}
.icon-lock-open:before {
  content: "\e073";
}
.icon-message:before {
  content: "\e074";
}
.icon-price-tag:before {
  content: "\e075";
}
.icon-save:before {
  content: "\e076";
}
.icon-retweet:before {
  content: "\e077";
}
.icon-bell:before {
  content: "\e078";
}
.icon-add-user:before {
  content: "\e079";
}
.icon-remove-user:before {
  content: "\e07a";
}
.icon-rocket:before {
  content: "\e07b";
}
.icon-rss:before {
  content: "\e07c";
}
.icon-pie-chart:before {
  content: "\e07d";
}
.icon-pin:before {
  content: "\e07e";
}
.icon-overview:before {
  content: "\e02c";
}
.icon-cross-thin:before {
  content: "\e044";
}
.icon-flickr-with-circle:before {
  content: "\e03e";
}
.icon-arrow-circle-o-down:before {
  content: "\e045";
}
.icon-login:before {
  content: "\e07f";
}
.icon-whatsapp:before {
  content: "\e080";
}
.icon-phone:before {
  content: "\e081";
}
.icon-phone-icon-jobstep:before {
  content: "\e082";
}
.icon-line-mail:before {
  content: "\e084";
}
.icon-line-phone:before {
  content: "\e085";
}
.icon-phone-call:before {
  content: "\e086";
}
.icon-trash:before {
  content: "\e087";
}
.icon-mobile:before {
  content: "\e088";
}
.icon-facebook-f:before {
  content: "\e02b";
}
.icon-file-pdf-o:before {
  content: "\e08a";
}
.icon-file-excel-o:before {
  content: "\e016";
}
.icon-youtube:before {
  content: "\e083";
}
.icon-file-image-o:before {
  content: "\e089";
}
.icon-file-powerpoint-o:before {
  content: "\e08b";
}
.icon-file-word-o:before {
  content: "\e08c";
}
.icon-file-video-o:before {
  content: "\e08d";
}
.icon-file-code-o:before {
  content: "\e08e";
}
.icon-file-audio-o:before {
  content: "\e08f";
}
.icon-file-archive-o:before {
  content: "\e090";
}
.icon-file-o:before {
  content: "\e092";
}
.icon-file-text-o:before {
  content: "\e091";
}
.icon-play-circle-o:before {
  content: "\e093";
}
